
function sortAscending(dataArray)
{
  dataArray.sort(function(a, b)
  {
    return a.name.localeCompare(b.name);
  });
  return dataArray;
}

export default sortAscending; 

